.person-info-container {
    display: flex;
    align-items: center;
}
  
.headshot-container {
    margin-right: 20px;
}
  
.headshot {
    width: 35vw;
    border-radius: 50%;
    object-fit: cover;
}

.stairs {
    width: 30vw;
    object-fit: cover;
}
  
.info {
    flex: 1; /* This makes the bio portion take up remaining space */
    max-width: calc(100% - (35vw + 20px)); /* Adjust as needed */
}