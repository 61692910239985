/* Footer base styles */
.footer {
    background-color: #492b7e;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    text-align: left;
}
  
/* Column styles */
.left-column, .center-column, .right-column {
    flex: 1;
}
  
/* Responsive styling for mobile */
@media (max-width: 768px) {
    footer {
      flex-direction: column;
      align-items: center;
    }
    
    .left-column, .center-column, .right-column {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
}
  

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}
  
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.footer-button {
	display: block;
	color: white;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	background-color: black;
	border: 2px solid black;
	padding: 8px 16px;
	text-align: center;
	border-radius: 5px;
	font-weight: 100;
	font-size: 1.75vw;
}
  
.footer-button:hover {
    background-color: rgba(73,43,126,1);
	color: white;
	border-color: rgba(73,43,126,1);
}

.modal-button {
    padding: 4px 10px;
    font-size: 1.75vw;
    cursor: pointer;
    margin: 5px;
    border: 2px solid black; /* Adds a black outline */
    background-color: white;
    color: black;
}
  
.modal-button:hover {
    background-color: rgba(73,43,126,1);
	color: white;
	border-color: rgba(73,43,126,1);
}
  