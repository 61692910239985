.navbar {
  background-color: black;
  overflow: visible;
  z-index: 1;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex; /* Use flexbox */
  justify-content: flex-end; /* Align items to the right */
}

.navbar ul li {
  display: inline; /* Make list items display inline */
  position: relative; /* Position the dropdown */
}

/* Style for dropdown */
.navbar ul li.right.dropdown .dropdown-content {
  display: none; /* Hide the dropdown by default */
  position: absolute; /* Position the dropdown */
  top: 100%; /* Position the dropdown below the tab */
  background-color: black;
  min-width: 160px;
  z-index: 1; /* Make sure the dropdown is above other content */
}

.navbar ul li.right.dropdown:hover .dropdown-content {
  display: block; /* Show the dropdown when hovering over the tab */
}

.navbar ul li.right.dropdown:hover .dropdown-content a {
  display: block;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
}

.navbar ul li.right.dropdown .dropdown-content a:hover {
  background-color: rgba(73,43,126,1);
}

.navbar ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar ul li a:hover {
  background-color: rgba(73,43,126,1);
  color: white;
}
