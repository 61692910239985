@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.impactvideo-container {
    display: flex;
}

.impactvideo-containter col {
    width: 100%;
}
  
.impactvideo-container iframe {
    flex: 1; /* Equal width for both videos */
    height: 400px; /* Adjust the height as needed */
    border: none; /* Remove iframe borders */
}    

/* src/components/NewsMentions.css */
.news-mentions {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}
  
.news-mentions h1 {
    text-align: center;
    margin-bottom: 40px;
}
  
.articles {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 2%;
}
  
.article {
    flex: 1 1 calc(50% - 20px);
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
}
  
.article:hover {
    transform: scale(1.02);
}
  
.article h2 {
    margin: 0 0 10px;
}
  
.article a {
    text-decoration: none;
    color: #1a0dab;
}
  
.article a:hover {
    text-decoration: underline;
}
  
.article p {
    margin: 5px 0;
}
  
@media (max-width: 768px) {
    .article {
      flex: 1 1 100%;
    }
}

.passwordarticle {
    flex: 1 1 calc(33% - 20px);
    box-sizing: border-box;
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
}
  
.passwordarticle:hover {
    transform: scale(1.03);
}