/* src/components/ImageTable.css */
.image-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}
  
.table {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}
  
.cell {
    cursor: pointer;
    border: 1px solid #ddd;
    padding: 5px;
    transition: transform 0.2s;
}
  
.cell:hover {
    transform: scale(1.05);
}
  
.cell img {
    width: 100%;
    height: auto;
}
  
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top of other elements */
}

.popup {
    width: 60%;
    height: 60%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1010; /* Ensure the popup is above the overlay */
}
  
.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    text-align: center;
}
  
.popup-content img {
    max-width: 100%;
    height: auto;
}
  
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 50px;
    cursor: pointer;
    color: black;
}  