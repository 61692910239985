@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.fullImg {
  position: relative;
  height: 90vh;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  color: rgba(240, 240, 240, 1);
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.backgroundImg {
  background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4));
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

.center {
  color: inherit;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centerTop {
  color: inherit;
  text-align: center;
  position: static;
  top: 10%;
  left: 50%;
  transform: translate(75%, 0%);
}

.left {
  color: inherit;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  padding: 5;
  width: 80%;
}

.container .row {
  display: block;
}

.button {
	display: block;
	color: white;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	background-color: black;
	border: 2px solid black;
	padding: 8px 16px;
	text-align: center;
	border-radius: 5px;
	font-weight: 100;
	font-size: 1.75vw;
}

.button:hover {
	background-color: rgba(73,43,126,1);
	color: white;
	border-color: rgba(73,43,126,1);
}

.footer-button {
	display: block;
	color: white;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	background-color: black;
	border: 2px solid black;
	padding: 8px 16px;
	text-align: center;
	border-radius: 5px;
	font-weight: 300;
	font-size: 1.75vw;
}

.footer-button:hover {
	background-color: rgba(73,43,126,1);
	color: white;
	border-color: rgba(73,43,126,1);
}

.box {
  position: relative;
  top: 0;
  border: 2px solid black;
  border-radius: 5px;
  margin: 1.0em;
  padding: 0.2em;
  box-align: center;
  font-family: "Roboto", sans-serif;
  background-color: white;
}

.main {
  max-width: 900px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box p {
  margin: 0.5em;
}

.box:hover {
  border: 2px solid rgba(73, 43, 126, 1);
}

.container {
  display: table;
  vertical-align: top;
  width: 100%;
}

.container .col {
  display: table-cell;
  vertical-align: top;
}

.container .colFT {
  display: table-cell;
  vertical-align: top;
  width: 33%;
}

.container .row {
  display: block;
}
@media (hover:none), (hover:on-demand), (pointer:coarse), (-moz-touch-enabled:1) {
  .container .col {
      display: block;
      width: 100% !important;
  }
  .container .row {
      display: table-cell;
      vertical-align: top;
  }
  .button {
      font-size: 3.5vw;
  }
}

#everything {
  background-color: white;
}

#table {
  max-width: 95vw;
  margin: auto;
  border-collapse: separate;
}

#table td {
  margin: 30px;
  padding: 30px;
}

#table tr {
  padding: 30;
}

.parragaPortrait img {
  padding: 20;
  margin: auto;
  max-width: 600px;
}

.ourworkimage {
  justify-content: center;
  width: 100%;
  position: relative;
  height: 90vh;
  margin-left: 100%;
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -ms-background-size: contain;
  -o-background-size: contain;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  color: rgba(240, 240, 240, 1);
}

.ourworkcontainer {
  display: table;
  vertical-align: top;
  margin-right: 0;
  width: 60%;
  /* translate: 100%; */
}

.ourworkcontainters {
  background-color:white;
  color:black;
  height:100%;
  width:60%;
  position:relative;
  left:100%;
  vertical-align:center;
}

#tinCanLung {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/ROB0042-008-V2-baseline.jpg");
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

#copdLung {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/COPD.jpg");
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

#healthyAgingLung {
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./images/HealthyAging.jpg");
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

#vapingLung {
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./images/ROB0042-019-V7.jpg");
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

#ethaLung {
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./images/ROB0046-005-V1-post-center.jpg");
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

#mechanicalLung {
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./images/redADCslices.jpg");
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

#theTeamTable tr td:first-child {
	width: 20%;
}

#theTeamTable tr td {
	padding: 1%;
}

#ourTeamBanner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url("./images/ourTeamBanner.jpg");
  background-color: black;
  color: rgba(240,240,240,0.9);
  background-position: 100%;
}

#ourTeamTable tr td {
  width: 60%;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.video-container video {
  max-width: 100%;
  height: auto;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-container img {
  max-width: 80%;
  height: auto;
}

.col-70 {
  width: 75%;
}

.col-30 {
  width: 25%;
}

.container-home {
  display: flex;
  width: 100%;
}