@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

#everything {
    background-color: white;
}

/* .container-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.workImg {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
}
  
/* Add similar styles for other images by id */
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
}
  
.work-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
  
.col {
    flex: 1;
    min-width: 200px;
}
  
.box {
    padding: 20px;
    background: #f9f9f9;
    margin: 10px;
    border-radius: 5px;
}  

.photo {
    flex-shrink: 2;
    margin-left: auto; /* Push the photo to the right */
}
  
.photo img {
    display: block; /* Ensure the image is block-level to remove any inline spacing */
    max-width: 100%;
    height: auto;
}